export const setupTabPanels = async (tabs) => {
  while (!tabs.hasUpdated) {
    await new Promise((r) => setTimeout(r, 50));
  }
  const panelId = tabs.activeTab?.getAttribute('aria-controls');
  const root = tabs.getRootNode();
  let currentPanel = root.querySelector(`#${panelId}`);

  tabs.addEventListener('change', () => {
    if (currentPanel) {
      currentPanel.hidden = true;
    }

    const panelId = tabs.activeTab?.getAttribute('aria-controls');
    const root = tabs.getRootNode();
    currentPanel = root.querySelector(`#${panelId}`);
    if (currentPanel) {
      currentPanel.hidden = false;
    }
  });
};

export const initializeTabs = () => {
  document.addEventListener('DOMContentLoaded', (event) => {
    const tabs = [...document.querySelectorAll('md-tabs')];
    for (const panel of tabs) {
      setupTabPanels(panel);
      panel.classList.remove('theme-invisible');
    }
    const pageTabs = document.querySelector('md-tabs.page');
    if (pageTabs) {
      window.addEventListener('hashchange', (event) => {
        event.preventDefault();
        if (location.hash) {
          const a = location.hash.split('?');
          const panel = document.querySelector(a[0]);
          if (panel) {
            const attr = panel.getAttribute('aria-labelledby');
            const tab = document.querySelector(`#${attr}`);
            pageTabs.activeTab = tab;
          } else {
            if (location.hostname === 'localhost') {
              console.warn(`Unable to find panel: ${a[0]}`);
            }
          }
        } else {
          pageTabs.activeTabIndex = 0;
        }
      }, false);
    }
  }, {once: true});
};

export const getRequestedTab = () => {
  let tabIndex;
  let params;
  if (location.search) {
    params = new URLSearchParams(location.search);
    tabIndex = params.get('tab');
    if (tabIndex) {
      return Number(tabIndex);
    }
  }
  if (location.hash) {
    const i = location.hash.indexOf('?');
    if (i !== -1) {
      params = new URLSearchParams(location.hash.substring(i));
      tabIndex = params.get('tab');
      if (tabIndex) {
        return Number(tabIndex);
      }
    }
  }
  return null;
};

export const activateRequestedTab = (tabs) => {
  const tabIndex = getRequestedTab();
  if (tabIndex) {
    tabs.activeTabIndex = tabIndex;
  } else {
    throw new Error('Unable to locate requested tab');
  }
};
