/**
 * FMPTouch: touck / swipe handling scripts
 * @license MIT
 * Adapted from kirupa
 * https://www.kirupa.com/html5/detecting_touch_swipe_gestures.htm
 *
 * @version 24.1.0
 * Module history
 *    8.1.0 - Initial build
 *    9.1.0 - material-components-web v10
 *   24.1.0 - Auto invoke class
 * @since December 2020
 * @author kirupa / Earl Rogers
 */

'use strict';

export class FMPTouch {
  constructor() {
    this.initialX = null;
    this.initialY = null;
    this.registerEvents();
  }

  startTouch(e) {
    this.initialX = e.touches[0].clientX;
    this.initialY = e.touches[0].clientY;
  }

  moveTouch(e) {
    if (this.initialX === null) {
      return;
    }

    if (this.initialY === null) {
      return;
    }

    const currentX = e.touches[0].clientX;
    const currentY = e.touches[0].clientY;

    const diffX = this.initialX - currentX;
    const diffY = this.initialY - currentY;

    if (Math.abs(diffX) > Math.abs(diffY)) {
      // sliding horizontally
      if (diffX > 0) {
        // swiped left
        // console.log("swiped left");
        const drawerEl = document.querySelector('.mdc-drawer');
        if (drawerEl) {
          // e.preventDefault();
          drawerEl.MDCDrawer.open = true;
        }
      } else {
        // swiped right
        // console.log("swiped right");
        switch (e.target.tagName.toLowerCase()) {
          case 'img': {
            let goBack = true;
            if (e.target.classList.contains('pswp__img')) {
              goBack = false;
            }
            if (goBack &&
              document.referrer.indexOf(window.location.host) !== -1) {
              // e.preventDefault();
              history.back();
            }
            break;
          }
          case 'md-tabs':
            break;
          default:
            if (document.referrer.indexOf(window.location.host) !== -1) {
              // e.preventDefault();
              history.back();
            }
        }
      }
    } else {
      // sliding vertically
      if (diffY > 0) {
        // swiped up
        // console.log("swiped up");
      } else {
        // swiped down
        // console.log("swiped down");
      }
    }

    this.initialX = null;
    this.initialY = null;
    /*
      // e.preventDefault();

      [Intervention] Unable to preventDefault inside passive
      event listener due to target being treated as passive.
      See https://www.chromestatus.com/features/5093566007214080
     */
  }

  registerEvents() {
    document.addEventListener('touchstart', this.startTouch, false);
    document.addEventListener('touchmove', this.moveTouch, false);
  }
}

new FMPTouch();
